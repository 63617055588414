html {
  overflow-x: hidden;
}
.INDmenuFreeMsg {
  display: none !important;
  max-height: 0px !important;
  & .ew-logo-svg {
    display: none !important;
  }
  & span {
    display: none !important;
  }
}
#INDmenu .INDmenuFooter div:last-child {
  max-height: 0px !important;
  & span {
    display: none !important;
  }
  & a {
    display: none !important;
  }
}
body {
  background: $px-black;
  color: $px-dark;
  font-family: $px-font;
  line-height: $px-line-height;
  font-size: $px-font-size;
  font-weight: 300;
  &.theme-light {
    background: $px-white;
    color: $px-body-light;
    font-weight: 400;
  }
}

.font-alt {
  font-family: $px-font-alt;
}

img {
  max-width: 100%;
}

* {
  outline: none !important;
}

a {
  color: $px-theme;
  transition: 0.3s;
  @include px-hover {
    color: darken($px-theme, 8%);
    text-decoration: none;
  }
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #0b0b13;
}
mark {
  background-image: linear-gradient($px-theme, $px-theme);
  background-size: 100% 3px;
  background-repeat: no-repeat;
  background-position: 0 bottom;
  background-color: transparent;
  padding: 0;
  color: currentColor;
}
.overlay {
  position: relative;
  > div {
    position: relative;
    z-index: 2;
  }
  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
  }
}
@media (min-width: 1400px) {
  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1140px;
  }
}
.ml-auto,
.mx-auto {
  margin-left: auto !important;
}
.media {
  display: flex !important;
  -ms-flex-align: start;
  align-items: flex-start;
}
.media-body {
  -ms-flex: 1;
  flex: 1;
}
a {
  text-decoration: none;
}
button,
a {
  cursor: pointer !important;
}
.invalid-feedback,
.valid-feedback {
  width: 100%;
  margin-top: 5px;
  font-size: 15px;
  display: block;
}
.invalid-feedback {
  color: #dc3545 !important;
}
.scroll_up {
  position: fixed;
  right: 20px;
  bottom: 20px;
  background-color: #fff;
  color: #5b52a3;
  width: 45px;
  height: 45px;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
  font-size: 25px;
  font-weight: 500;

  @media screen and (max-width: 767px) {
    display: none;
  }

  &:hover {
    transform: translateY(-4px);
  }
}
.scroll_up_ar {
  position: fixed;
  left: 20px;
  bottom: 20px;
  background-color: #fff;
  color: #5b52a3;
  width: 45px;
  height: 45px;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
  font-size: 25px;
  font-weight: 500;

  @media screen and (max-width: 767px) {
    display: none;
  }

  &:hover {
    transform: translateY(-4px);
  }
}
// react masonry
.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -20px; /* gutter size offset */
  width: auto;
  @media screen and (min-width: 1366px) {
    min-height: 740px;
  }
}
.my-masonry-grid_column {
  padding-left: 20px; /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div {
  /* change div to reference your elements you put in <Masonry> */
  background: grey;
  margin-top: 20px;
}
/* ----------------------
*	Loading
---------------------------*/
#loading {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: $px-theme;
  z-index: 99999;
}
.load-circle {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 50px;
  height: 50px;
  span {
    display: inline-block;
    width: 64px;
    height: 64px;
    &:after {
      content: " ";
      display: block;
      width: 46px;
      height: 46px;
      margin: 1px;
      border-radius: 50%;
      border: 5px solid $px-white;
      border-color: $px-white transparent $px-white transparent;
      animation: lds-dual-ring 1.2s linear infinite;
    }
  }
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* ----------------------
*	owl 
---------------------------*/
.owl-dots {
  text-align: center;
  .owl-dot {
    display: inline-block;
    vertical-align: top;
    width: 10px;
    height: 10px;
    background: transparent;
    border: 1px solid $px-white;
    @include transition(ease all 0.55s);
    border-radius: 50%;
    margin: 0 5px;
    .theme-light & {
      border-color: $px-dark;
    }
    &.active {
      background: $px-white;
      .theme-light & {
        background: $px-dark;
      }
    }
  }
}

.owl-carousel {
  .owl-item {
    img {
      width: auto;
      max-width: 100%;
    }
  }
}

.reader-hidden {
  font-size: 0;
}
